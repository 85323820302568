<template>
  <b-container no-gutters class="settings-coa-budgets px-0">
    <div class="bordered-table mt-3">
      <b-row no-gutters>
        <b-col>
          <h2>{{ $t(translationPath + 'title') }}</h2>
        </b-col>
        <b-col v-if="!isEditFormShown && isLoaded">
          <b-link class="btn btn-xs btn-secondary float-right mb-2 mb-md-0" @click.prevent="onBtnAddBudgetClick">
            <i class="flaticon stroke plus"></i>
            {{ $t(translationPath + "table.buttons.add_budget") }}
          </b-link>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <p>{{ $t(translationPath + 'description') }}</p>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <template v-if="isEditFormShown">
            <hr />
            <h3 v-if="!editItemId">{{ $t(translationPath + 'form.title_add') }}</h3>
            <h3 v-else>{{ $t(translationPath + 'form.title_edit') }}</h3>
            <b-form>
              <b-form-group
                label-class="align-self-top pt-lg-3 pr-md-0 pr-lg-4 text-black"
                label-for="title"
                label-cols-lg="3"
                label-cols-xl="3"
                label-align-lg="right"
                class="pt-md-4"
                :label="$t(translationPath + 'form.fields.title_name')"
                :description="$t(translationPath + 'form.fields.title_description')"
                :invalid-feedback="invalidTitleFeedback"
              >
                <b-form-input
                  v-model="editItemTitle"
                  class="rounded-pill"
                  :state="$v.editItemTitle.$dirty ? !$v.editItemTitle.$error : null"
                />
              </b-form-group>

              <b-form-group
                label-class="align-self-top pt-lg-3 pr-md-0 pr-lg-4 text-black"
                label-for="description"
                label-cols-lg="3"
                label-cols-xl="3"
                label-align-lg="right"
                class="pt-md-4"
                :label="$t(translationPath + 'form.fields.description_name')"
                :description="$t(translationPath + 'form.fields.description_description')"
                :invalid-feedback="invalidDescriptionFeedback"
              >
                <b-form-textarea
                  v-model="editItemDescription"
                  rows="3"
                  max-rows="3"
                  :state="$v.editItemDescription.$dirty ? !$v.editItemDescription.$error : null"
                />
              </b-form-group>

              <b-row>
                <b-col class="pt-4 pt-md-1">
                  <b-link class="float-right btn-primary btn" @click="onBtnFormSaveClick">
                    {{ $t("common.save") }}
                  </b-link>
                  <b-link class="float-right btn-secondary btn mr-2" @click="onBtnFormCancelClick">
                    {{ $t("common.cancel") }}
                  </b-link>
                </b-col>
              </b-row>
            </b-form>
          </template>
          <template v-else>
            <b-table
              :items="tableItems"
              :fields="tableFields"
              :busy="!isLoaded"
              stacked="md"
              class="spirecta-simple-table mb-0"
              responsive
              striped
              show-empty
            >
              <template v-slot:table-busy>
                <loader />
              </template>

              <template v-slot:cell(title)="row">
                {{ row.value }}
                <b-badge v-if="row.item.id === currentCOA.default_budget_id" variant="info">{{ $t(translationPath + 'table.badges.default') }}</b-badge>
              </template>

              <template v-slot:cell(actions)="row">
                <b-button
                  v-if="row.item.id !== currentCOA.default_budget_id"
                  @click="onRowDefaultClick(row.item)"
                  :title="$t(translationPath + 'table.buttons.set_default')"
                  class="plain-btn action-button mr-2"
                >
                  <i class="flaticon solid star-2 text-info"></i
                ></b-button>
                <b-button
                  @click="onRowEditClick(row.item)"
                  :title="$t('common.edit')"
                  class="plain-btn action-button mr-2"
                >
                  <i class="flaticon solid edit-3 text-success"></i
                ></b-button>
                <b-button
                  @click="onRowCopyClick(row.item)"
                  :title="$t(translationPath + 'table.buttons.copy_budget')"
                  class="plain-btn action-button"
                  :class="{ 'mr-2': row.item.id !== currentCOA.default_budget_id }"
                >
                  <i class="flaticon solid copy-3 text-success"></i
                ></b-button>
                <b-button
                  v-if="row.item.id !== currentCOA.default_budget_id"
                  @click="onRowDeleteClick(row.item)"
                  :title="$t('common.delete')"
                  class="plain-btn action-button"
                >
                  <i class="flaticon solid trash-3 text-danger"></i
                ></b-button>
              </template>
            </b-table>
          </template>
        </b-col>
      </b-row>
    </div>

    <delete-modal
      @on-delete-cancel="onDeleteCancel"
      @on-delete-confirm="onDeleteConfirm"
      ref="DeleteBudgetModal"
      :heading="$t(translationPath + 'modal_delete.title', { title: this.editItemTitle })"
      :message="$t(translationPath + 'modal_delete.text')"
    />

    <info-modal
      ref="DeleteDefaultBudgetModal"
      :title="$t(translationPath + 'modal_delete_default.title', { title: this.editItemTitle })"
      :message="$t(translationPath + 'modal_delete_default.text')"
      @ok="resetEditForm"
    />
  </b-container>
</template>

<script>
import Loader from '@/components/common/Loader'
import axios from 'axios'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import DeleteModal from '@/components/modals/DeleteModal'
import InfoModal from '@/components/modals/InfoModal'
import { mapState, mapActions } from 'vuex'

const isTitleUnique = getter => function () {
  const titles = []
  this.tableItems.map(item => {
    if (item.id !== this.editItemId) {
      titles.push(item.title)
    }
  })
  if (titles.indexOf(this.editItemTitle) === -1) {
    return true
  }
  return false
}

export default {
  name: 'Budgets',
  components: { Loader, DeleteModal, InfoModal },
  data () {
    return {
      isLoaded: false,
      tableItems: [],
      translationPath: 'settings.tab_budgets.',
      isEditFormShown: false,
      isBtnFormSaveDisabled: false,
      editItemId: null,
      editItemTitle: '',
      editItemDescription: ''
    }
  },
  validations: {
    editItemTitle: { required, minLength: minLength(2), maxLength: maxLength(255), isTitleUnique: isTitleUnique() },
    editItemDescription: { maxLength: maxLength(65535) },
    form: ['editItemTitle', 'editItemDescription']
  },
  computed: {
    ...mapState('user', ['currentCOA']),
    tableFields () {
      return [
        { key: 'title', label: this.$t('common.title'), sortable: true },
        { key: 'description', label: this.$t('common.description'), sortable: true },
        { key: 'actions', label: '', sortable: false, class: 'text-right' }
      ]
    },
    invalidTitleFeedback () {
      if (this.$v.editItemTitle.required === false) return this.$t(this.translationPath + 'form.errors.title.required')
      if (this.$v.editItemTitle.minLength === false) return this.$t(this.translationPath + 'form.errors.title.min_length')
      if (this.$v.editItemTitle.maxLength === false) return this.$t(this.translationPath + 'form.errors.title.max_length')
      if (this.$v.editItemTitle.isTitleUnique === false) return this.$t(this.translationPath + 'form.errors.title.not_unique')
      return ''
    },
    invalidDescriptionFeedback () {
      if (this.$v.editItemDescription.maxLength === false) return this.$t(this.translationPath + 'form.errors.description.max_length')
      return ''
    }
  },
  methods: {
    ...mapActions('user', ['loadCurrentCOA']),
    async loadData (bBackgroundLoad = false) {
      if (!bBackgroundLoad) {
        this.isLoaded = false
      }

      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_ROOT_API}/budgets`)
          .then(response => {
            this.tableItems = response.data.data
            this.isLoaded = true
            resolve(response.data.data)
          })
          .catch(err => {
            console.error(err)
            reject(err)
          })
      })
    },
    onBtnAddBudgetClick () {
      this.isEditFormShown = true
      this.resetEditForm()
    },
    onBtnFormCancelClick () {
      this.isEditFormShown = false
      this.resetEditForm()
    },
    onBtnFormSaveClick () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid || this.isBtnFormSaveDisabled) {
        return false
      }

      this.isBtnFormSaveDisabled = true
      const postPutData = {
        title: this.editItemTitle,
        description: this.editItemDescription
      }

      let sApiEndpoint = `${process.env.VUE_APP_ROOT_API}/budgets`
      let sApiMethod = 'post'
      if (this.editItemId) {
        sApiMethod = 'put'
        sApiEndpoint += `/${this.editItemId}`
      }

      axios({
        method: sApiMethod,
        url: sApiEndpoint,
        data: postPutData
      })
        .then(response => {
          this.$bvToast.toast(this.$t(this.translationPath + 'form.toast.success'), {
            title: this.$t(this.translationPath + 'form.toast.title'),
            variant: 'success',
            solid: true
          })
          this.isEditFormShown = false
          this.loadData(true)
          this.resetEditForm()
        })
        .catch(err => {
          console.error(err)
          this.$bvToast.toast(this.$t(this.translationPath + 'form.toast.fail'), {
            title: this.$t(this.translationPath + 'form.toast.title'),
            variant: 'danget',
            solid: true
          })
        })
        .then(() => {
          this.isBtnFormSaveDisabled = false
        })
    },
    resetEditForm () {
      this.editItemId = null
      this.editItemTitle = ''
      this.editItemDescription = ''
      this.$v.form.$reset()
    },
    onRowEditClick (item) {
      this.editItemId = item.id
      this.editItemTitle = item.title
      this.editItemDescription = item.description
      this.isEditFormShown = true
    },
    onRowDeleteClick (item) {
      this.editItemTitle = item.title
      if (item.id === this.currentCOA.default_budget_id) {
        this.$refs.DeleteDefaultBudgetModal.show()
      } else {
        this.editItemId = item.id
        this.$refs.DeleteBudgetModal.show()
      }
    },
    onDeleteCancel () {
      this.resetEditForm()
    },
    onDeleteConfirm () {
      axios.delete(`${process.env.VUE_APP_ROOT_API}/budgets/${this.editItemId}`)
        .then(response => {
          this.$bvToast.toast(this.$t(this.translationPath + 'modal_delete.toast.success', { title: this.editItemTitle }), {
            title: this.$t(this.translationPath + 'modal_delete.toast.title'),
            variant: 'success',
            solid: true
          })
        })
        .catch(err => {
          console.error(err)
          this.$bvToast.toast(this.$t(this.translationPath + 'modal_delete.toast.fail', { title: this.editItemTitle }), {
            title: this.$t(this.translationPath + 'modal_delete.toast.title'),
            variant: 'danger',
            solid: true
          })
        })
        .then(() => {
          this.loadData(true)
          this.resetEditForm()
        })
    },
    onRowDefaultClick (item) {
      const putData = { default_budget_id: item.id }
      axios.put(`${process.env.VUE_APP_ROOT_API}/charts-of-accounts/${this.currentCOA.id}`, putData)
        .then(response => {
          this.$bvToast.toast(this.$t(this.translationPath + 'set_default_budget.toast.success', { title: item.title }), {
            title: this.$t(this.translationPath + 'set_default_budget.toast.title'),
            variant: 'success',
            solid: true
          })
          // this.loadData(true)
          this.loadCurrentCOA()
        })
        .catch(err => {
          console.error(err)
          this.$bvToast.toast(this.$t(this.translationPath + 'set_default_budget.toast.fail'), {
            title: this.$t(this.translationPath + 'set_default_budget.toast.title'),
            variant: 'danger',
            solid: true
          })
        })
    },
    onRowCopyClick (item) {
      axios.post(`${process.env.VUE_APP_ROOT_API}/budgets/copy/${item.id}`)
        .then(response => {
          this.$bvToast.toast(this.$t(this.translationPath + 'copy_budget.toast.success', { title: item.title }), {
            title: this.$t(this.translationPath + 'copy_budget.toast.title'),
            variant: 'success',
            solid: true
          })
          this.loadData(true)
        })
        .catch(err => {
          this.$bvToast.toast(this.$t(this.translationPath + 'copy_budget.toast.fail'), {
            title: this.$t(this.translationPath + 'copy_budget.toast.title'),
            variant: 'success',
            solid: true
          })
          console.error(err)
        })
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>

<style lang="scss">
.settings-coa-budgets {
  table {
    .action-button {
      padding: 0;
      border: none;
      line-height: normal;
      i {
        margin: 0;
        font-size: 13px;
      }
      &:hover {
        background: transparent;
      }
      &:active {
        background-color: transparent !important;
        border-color: transparent !important;
      }
      &:focus {
        background: transparent;
        box-shadow: none !important;
      }
    }
  }
}
</style>
